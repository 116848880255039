import React from 'react'

import Swiper from 'react-id-swiper'
import 'assets/scss/swiper.scss'

import { Grid } from '@material-ui/core'

import arrow from 'assets/images/freccia.png'

import c_passioni from 'assets/images/categories/passioni.jpg'
import c_sport from 'assets/images/categories/sport.jpg'
import c_tech from 'assets/images/categories/tech.jpg'
import c_benessere from 'assets/images/categories/benessere.jpg'
import c_ricette from 'assets/images/categories/ricette.jpg'
import c_viaggi from 'assets/images/categories/viaggi.jpg'
import c_casa from 'assets/images/categories/casa.jpg'
import c_shopping from 'assets/images/categories/shopping.jpg'
import c_charity from 'assets/images/categories/charity.jpg'

const carouselParams = {
	pagination: {
		el: '.swiper-pagination',
		type: 'bullets',
		clickable: true,
	},
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	loop: true,
	centeredSlides: true,
}

const Product = (props) => {
	return (
		<div className="carousel">
			<Swiper {...carouselParams}>
				<div className="slide">
					<Grid container className="slideGrid" direction="row" spacing={1} justifyContent="space-between" alignItems="flex-start">
						<Grid className="category" item xs={12} sm={12} md={4}>
							<a href="/premi/passioni">
								<img alt="passioni" className="image" src={c_passioni} />
								<div className="name">Passioni</div>
								<img alt="passioni_a" className="arrow" src={arrow} />
							</a>
						</Grid>

						<Grid className="category" item xs={12} sm={12} md={4}>
							<a href="/premi/sport">
								<img alt="sport" className="image" src={c_sport} />
								<div className="name">Sport</div>
								<img alt="sport_a" className="arrow" src={arrow} />
							</a>
						</Grid>

						<Grid className="category" item xs={12} sm={12} md={4}>
							<a href="/premi/tech">
								<img alt="tech" className="image" src={c_tech} />
								<div className="name">Tech</div>
								<img alt="tech_a" className="arrow" src={arrow} />
							</a>
						</Grid>
					</Grid>
				</div>

				<div className="slide">
					<Grid container className="slideGrid" direction="row" spacing={1} justifyContent="space-between" alignItems="flex-start">
						<Grid className="category" item xs={12} sm={12} md={4}>
							<a href="/premi/benessere">
								<img alt="benessere" className="image" src={c_benessere} />
								<div className="name">Benessere</div>
								<img alt="benessere_a" className="arrow" src={arrow} />
							</a>
						</Grid>

						<Grid className="category" item xs={12} sm={12} md={4}>
							<a href="/premi/ricette">
								<img alt="ricette" className="image" src={c_ricette} />
								<div className="name">Ricette</div>
								<img alt="ricette_a" className="arrow" src={arrow} />
							</a>
						</Grid>

						<Grid className="category" item xs={12} sm={12} md={4}>
							<a href="/premi/viaggi">
								<img alt="viaggi" className="image" src={c_viaggi} />
								<div className="name">Viaggi</div>
								<img alt="viaggi_a" className="arrow" src={arrow} />
							</a>
						</Grid>
					</Grid>
				</div>

				<div className="slide">
					<Grid container className="slideGrid" direction="row" spacing={1} justifyContent="space-between" alignItems="flex-start">
						<Grid className="category" item xs={12} sm={12} md={4}>
							<a href="/premi/casa">
								<img alt="casa" className="image" src={c_casa} />
								<div className="name">Casa</div>
								<img alt="casa_a" className="arrow" src={arrow} />
							</a>
						</Grid>

						<Grid className="category" item xs={12} sm={12} md={4}>
							<a href="/premi/shopping">
								<img alt="shopping" className="image" src={c_shopping} />
								<div className="name">Shopping</div>
								<img alt="shopping_a" className="arrow" src={arrow} />
							</a>
						</Grid>

						<Grid className="category" item xs={12} sm={12} md={4}>
							<a href="/premi/charity">
								<img alt="charity" className="image" src={c_charity} />
								<div className="name">Charity</div>
								<img alt="charity_a" className="arrow" src={arrow} />
							</a>
						</Grid>
					</Grid>
				</div>
			</Swiper>
		</div>
	)
}

export default Product
