import config_prod from './config.prod.js'
import config_dev from './config.dev.js'
let config;

if(process.env.NODE_ENV === 'production'){
	config = config_prod
}else{
	config = config_dev
}

export default config