import React from 'react'

import Img from 'react-image'
import 'assets/scss/swiper.scss'

import { Grid } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

import * as utils from 'utils.js'
import { Header } from './components'
import { Card, Carousel } from '../components'

import x from 'assets/images/x.png'
import productImage from 'assets/images/product.png'

import axios from 'axios'
import config from 'config'
import linkifyHtml from 'linkifyjs/html'
import Avviso from 'views/components/Avviso'

const Product = (props) => {
	const [product, setProduct] = React.useState(null)
	const [showcase, setShowcase] = React.useState([])

	React.useEffect(() => {
		if (!product) return

		axios({
			method: 'post',
			url: `${config.apiURL}/api/v1/items/showcase`,
			data: {
				item_id: product.id,
			},
		})
			.then((res) => {
				if (!res.data) {
					setShowcase([])
				} else {
					setShowcase(res.data)
				}
			})
			.catch((error) => {
				if (config.debug) console.log(error)
			})
	}, [product])

	React.useEffect(() => {
		axios({
			method: 'post',
			url: `${config.apiURL}/api/v1/items/slug`,
			data: {
				slug: props.match.params.name,
			},
		})
			.then((res) => {
				if (!res.data) {
					setProduct(null)
				} else {
					setProduct(res.data)
				}
			})
			.catch((error) => {
				if (config.debug) console.log(error)
			})

		utils.scrollTo(document.querySelector('html'))
	}, [props.match.params.name])

	return (
		<div className="Product" id="product">
			<Header />

			<Avviso />

			{product && (
				<div className="productDescription">
					<div className="closeContainer">
						<div
							onClick={(e) => {
								props.history.goBack()
								// if (document.referrer) {
								// 	props.history.goBack()
								// } else {
								// 	props.history.push(`/`)
								// }

								return false
							}}
						>
							<img alt="chiudi" src={x} />
						</div>
					</div>

					<div className="dataContainer">
						<Grid container direction="row" spacing={1} justifyContent="center" alignItems="flex-start">
							<Grid item xs={12} md={5} className="left">
								<div className="name">
									<span className="brand">{product.brand.toLowerCase()}</span> - {product.name}
								</div>
								<Img
									className="image"
									alt={product.name}
									src={[`https://storage.googleapis.com/iosi-bucket1/${product.picturename}`, productImage]}
									loader={<Skeleton className="loader" variant="rect" width={220} height={165} />}
									unloader={<Skeleton className="loader" variant="rect" width={220} height={165} animation={false} />}
								/>
							</Grid>

							<Grid item xs={12} md={6} className="right">
								<div className="points">
									<div className="base">
										{product.points} <span className="small">punti</span>
									</div>
									<div className="alternatives">
										{product.alternatives.map((alt, i) => (
											<div key={i} className="alternative">
												<span className="bold">{alt.pt}</span>
												pt +<span className="bold">{alt.e}€</span>
											</div>
										))}
									</div>
								</div>

								<div className="description">
									<div className="title">DESCRIZIONE</div>
									<div
										className="text"
										dangerouslySetInnerHTML={{ __html: linkifyHtml(product.description, { attributes: { rel: 'noopener noreferrer' } }) }}
									></div>
								</div>

								<a className="request" href="https://www.nexi.it/login-titolari.html?DL=iocollection&productType=CREDIT">
									RICHIEDI
								</a>
							</Grid>
						</Grid>
					</div>
				</div>
			)}

			{props.match.params.str && (
				<div className="notFound">
					<b>404</b>
					<br />'{props.match.params.str}' not found.
				</div>
			)}

			{product && showcase.length > 0 && (
				<div className="showcase">
					<div className="title">Vetrina</div>
					<Grid container direction="row" spacing={3} justifyContent="center" alignItems="flex-start">
						{showcase.map((item, i) => (
							<Grid key={i} item xs={12} sm={12} md={4} lg={4} xl={4}>
								<Card history={props.history} item={item} />
							</Grid>
						))}
					</Grid>
				</div>
			)}

			<Carousel />
		</div>
	)
}

export default Product
