import React from 'react';
import qs from 'qs';
import {
  useLocation
} from "react-router-dom";

const setQueryStringWithoutPageReload = qsValue => {
    const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${qsValue}`;
    window.history.pushState({ path: newurl }, '', newurl);
};

const setQueryStringValue = (key, value, queryString = window.location.search) => {
	 const values = qs.parse(queryString, {ignoreQueryPrefix: true});
	 const newQsValue = qs.stringify({ ...values, [key]: value });
	 setQueryStringWithoutPageReload(`?${newQsValue}`);
 };

const getQueryStringValue = (key, queryString = window.location.search) => {
    const values = qs.parse(queryString);
    return values[key];
};


const useQueryString = (key, initialValue) => {
  const [value, setValue] = React.useState(getQueryStringValue(key) || initialValue);

  const onSetValue = React.useCallback(
    newValue => {
      setValue(newValue);
      setQueryStringValue(key, newValue);
    },
    [key]
  );


  //fix back button
  let location = useLocation();
  React.useEffect(() => {
    const values = qs.parse(location.search, {ignoreQueryPrefix: true});
    setValue(values[key] || initialValue)
  }, [location]);

  return [value, onSetValue];
}

export default useQueryString;