import * as Papa from 'papaparse';
import iconv from 'iconv-lite';
import { Buffer } from 'buffer';

let items = null;

export const indexOf = {
	idType: 0,
	id: 1,
	name: 2,
	description: 3,
	brand: 4,
	category: 5,
	type: 6,
	euro: 7,
	points: 8,

	pointsAlt1p: 9, //euro_alt_1
	pointsAlt1e: 10,
	pointsAlt2p: 11,
	pointsAlt2e: 12,
	pointsAlt3p: 13,
	pointsAlt3e: 14,

	courier: 19,
	picturename: 23,


}

export const getItems = (cb) => {
	if(items){
		if(cb) cb(items);
		return;
	}

	fetch('/src.csv')
      .then(res => res.arrayBuffer())
      .then(arrayBuffer => iconv.decode(new Buffer(arrayBuffer), 'iso-8859-1').toString())
      .then(converted => {
		//console.log(converted);
		Papa.parse(converted, {
			//encoding: 'ISO-8859-1',
			download: false,
			skipEmptyLines: true,
			complete: function(res) {
				items = res.data;
				  if(cb) cb(items);
			}
		  });
	  })
}

export const getName = item => {
	return item[indexOf.name]
        .split('-')[1]
        .trim()
}

export const getUrlName = item => {
	return item[indexOf.name]
        .split('-')[1]
        .trim()
        .replace(/[^\w\s]+[\r?\n]+[\n]/gi, '')
        .toLowerCase()
        .split(' ').join('-') + `-${item[indexOf.id]}`
}

export const format = n => {
	return Number(n).toLocaleString()
}

export const getPoints = item => {
	return format(item[indexOf.points]);
}

export const getAlternatives = item => {
	let res = [];

	if(item[indexOf.pointsAlt1p] && item[indexOf.pointsAlt1e]){
		res.push({
			pt: format(item[indexOf.pointsAlt1p]),
			e: format(item[indexOf.pointsAlt1e]),
		})
	}

	if(item[indexOf.pointsAlt2p] && item[indexOf.pointsAlt2e]){
		res.push({
			pt: format(item[indexOf.pointsAlt2p]),
			e: format(item[indexOf.pointsAlt2e]),
		})
	}

	if(item[indexOf.pointsAlt3p] && item[indexOf.pointsAlt3e]){
		res.push({
			pt: format(item[indexOf.pointsAlt3p]),
			e: format(item[indexOf.pointsAlt3e]),
		})
	}

	return res;
}
export const getShowcase = (items) => {
	let res = [items[0], items[1], items[2]];
	return res;
}

export const scrollTo = (element) => {
	window.scroll({
	  behavior: 'smooth',
	  left: 0,
	  top: element.offsetTop
	});
  }