import React from 'react';

import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import './assets/scss/index.scss';

import config from 'config';

import { PublicRoutes } from 'routes';

import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';
if(!config.debug) {
  TagManager.initialize({
    gtmId: config.google.tag_manager_id
  });
  ReactGA.initialize(config.google.analytics_monitoring_id);
}


const browserHistory = createBrowserHistory();
browserHistory.listen(location => {
	ReactGA.set({ page: location.pathname }); // Update the user's current page
	ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router history={browserHistory}>
        <PublicRoutes />
      </Router>
    </ThemeProvider>
  );
}

export default App;
