import React from 'react';
import PropTypes from 'prop-types';
import  classNames from 'classnames';

import { Topbar, Footer } from './components';

const Main = props => {

  const [showCookies, setShowCookies] = React.useState(!localStorage.cookiePolicyShown)
  const [cookiesOpen, setCookiesOpen] = React.useState(false);

  React.useEffect(() => {
    localStorage.cookiePolicyShown=true;
  },[]);


  return (
    <div className="MainLayout">

      <Topbar />

      <main className="content">
        {props.children}
      </main>

      <Footer />

      {showCookies &&

        <div id="cl-banner-wrapper">


          {!cookiesOpen &&
            <div className="cl-banner-body">
            <div className="cl-banner-logo ICBPI"></div>
            <div className="cl-banner-content">
              <div style={{display:"inline"}}>Utilizziamo i cookie per assicurarti la migliore esperienza sul nostro sito.
                Questo sito utilizza i cookie, anche di terze parti, per inviarti messaggi promozionali
                personalizzati.Per saperne di più, conoscere i cookie utilizzati dal sito ed eventualmente
                disabilitarli,</div>
              <div
                onClick={() => setCookiesOpen(true)}
                style={{display:"inline"}}
                className="cl-policy close
              ">
                accedi alla Cookie Policy
              </div>
              <div style={{display:"inline"}}>Se prosegui nella navigazione di questo sito acconsenti all´utilizzo dei cookie.
              </div>
            </div>
            <div className="cl-consent-button-x-container">
              <div
                className="cl-consent-button-x iconclose"
                onClick={() => setShowCookies(false)}
              >
                  ✕
              </div>
            </div>
            <div className="cl-clear"></div>
          </div>
          }

          {cookiesOpen &&
            <div className="cl-banner-detail cl-bottom">
              <div className="cl-banner-detailcontent">
                <div className="cl-consent-button-x-container">
                  <div
                    onClick={() => setCookiesOpen(false)}
                    className="cl-close-detailcontent iconclose"
                  >
                    ✕
                  </div>
                </div>
                <div className="cl-policy-container tabcontent cl-custom-white-scrollbar">
                  <div>
                    <div>
                      <p> </p>
                      <div className="cl-text-bold_600 cl-text-font-16">Che cosa sono i cookies e come vengono utilizzati
                      </div> <br /> <br />I cookies sono stringhe di testo di piccole dimensioni scambiati tra un server
                      ed il web client. Nexi utilizza le seguenti tipologie di cookies.<br /><br /><strong> 1. Cookie di
                        sessione</strong> <br /> <br />Nexi utilizza propri <strong>cookie di sessione</strong>,
                      essenziali per navigare sul sito istituzionale di Nexi e per consentire funzioni quali
                      l’autentificazione, la validazione, la gestione di una sessione di navigazione e la prevenzione
                      delle frodi.<p></p>
                      <p><strong> 2. Cookie Tecnici</strong> <br /> <br />Nexi utilizza cookie di performance o
                        <strong>cookie tecnici o analitici,</strong> che non richiedono il consenso, per finalità
                        statistiche di accesso alle pagine del sito e allo scopo di offrirti un’esperienza digitale
                        rapida e sicura, ad esempio, permettendoti di mantenere attiva la connessione all’area
                        protetta durante la navigazione attraverso le pagine del sito; memorizzare, in modalità
                        sicura, i tuoi UserID e Password; personalizzare la tua homepage o identificare le pagine
                        del sito che hai visitato, per evitare che vengano riproposte. <br /> <br />Le pagine Web
                        possono inoltre contenere delle immagini particolari che consentono di determinare se una
                        certa pagina è stata visualizzata o meno e, in caso affermativo, conteggiare il numero di
                        utenti che l’hanno visualizzata. <br /> <br />Nexi inoltre utilizza alcuni cookie tecnici o
                        analitici di terze parti che migliorano la tua esperienza di navigazione come ad esempio:
                        <br /> <br /> </p>
                      <div  className="cl-custom-text-bold-and-color">Google Analytics</div> <br /><a
                        rel="noopener"
                        href="https://www.google.it/intl/it/policies/technologies/cookies/"
                        target="_blank">https://www.google.it/intl/it/policies/technologies/cookies/</a> <br /> <br />
                      <div className="cl-custom-text-bold-and-color">WebTrends</div> <br /><a
                        rel="noopener"
                        href="https://www.webtrends.com/" target="_blank">https://www.webtrends.com</a> <br /> <br />
                      <div className="cl-custom-text-bold-and-color">YouTube</div> <br /><a href="https://www.youtube.com"
                        rel="noopener"
                        target="_blank">https://www.youtube.com</a> <br /> <br />
                      <div className="cl-custom-text-bold-and-color">IoSi Viaggi</div> <br /><a
                        rel="noopener"
                        href="http://iosiviaggi.it/nexi-iosi-viaggi-chi-siamo"
                        target="_blank">http://iosiviaggi.it/nexi-iosi-viaggi-chi-siamo</a> <br /> <br />
                      <div className="cl-custom-text-bold-and-color">Extraturista</div> <br /><a
                        rel="noopener"
                        href="http://www.jakala.com" target="_blank">http://www.jakala.com</a> <br /> <br />
                      <div className="cl-custom-text-bold-and-color">#iosi collection</div> <br /><a
                        rel="noopener"
                        href="http://www.drupalitalia.org/" target="_blank">http://www.drupalitalia.org/</a> <br />
                      <br />
                      <p></p>
                      <p>Nexi non permette alla terza parte di usare i cookie per fini diversi da quelli sopra
                        elencati. <br />In particolare, il sistema Google Analytics, è un servizio di analisi web
                        fornito da Google, Inc. ("Google"). Google Analytics, come gli altri sistemi, utilizza dei
                        "cookies", che sono file di testo che vengono depositati sul computer dell’utente per
                        consentire al sito web di analizzare come gli utenti utilizzano il sito. <br /> <br />Puoi in
                        ogni caso rifiutarti di usare i cookies selezionando l’impostazione appropriata sul tuo
                        browser, ma ciò potrebbe impedire di utilizzare tutte le funzionalità del sito web. <br />
                        <br /> </p>
                      <p></p>
                      <p><strong> 3. Cookie Pubblicitari o di Profilazione</strong> <br /> <br />I <strong>cookie
                          pubblicitari di profilazione</strong> sono utilizzati per raccogliere informazioni sulle
                        tue abitudini di navigazione con lo scopo di proporre messaggi pubblicitari il più possibile
                        rilevanti per i tuoi interessi. Ciò significa che vengono utilizzati anche per limitare il
                        numero di volte in cui visualizzi una determinata pubblicità e per comunicare in modo più
                        efficace, per te significa ricevere pubblicità meno invasiva e più vicina alle tue
                        preferenze. <br /> Nexi utilizza anche <u>cookie di terze parti</u> (Cookies promozionali o di
                        targeting) per migliorare in generale l’esperienza dell’utente sul proprio sito. Questi
                        cookies, gestiti da società di terze parti, sono: </p>
                      <p></p>
                      <p> </p>
                      <div className="cl-custom-text-bold-and-color">uid - cid - tuuid - GCM - id - TPC</div>
                      <div>(Cookies installati dal Portale Titolari e nell’Area Personale clienti privati per gestione
                        della Campagna di Retargeting del servizio Nexi Pay) </div>
                      <div>In merito all’installazione dei cookie personalizzati con finalità commerciali e basati
                        sulla mia navigazione, dichiaro che </div>
                      <div className="cl-checkbox-container">
                        <div className="checkbox"><br /> id="adform-disable" type="radio" name="adform" value="disable"
                            className="cl-custom-input-checkbox" /> <label for="adform-disable"> Non desidero
                            l’installazione</label> <span className="lowInputLabel">Non desidero
                            l’installazione</span> </div>
                        <div className="checkbox"><input id="adform-enable" type="radio" name="adform" value="enable"
                            checked="checked" className="cl-custom-input-checkbox" /> <label for="adform-enable">
                            Desidero installarli</label> <span className="lowInputLabel">Desidero installarli</span>
                        </div>
                        <p></p>
                      </div> <br />Con specifico riferimento all’erogazione di digital advertising, Nexi si avvale di
                      terze parti, di seguito è possibile consultare le specifiche cookie policies di ciascuna delle
                      terze parti coinvolte: <br /><br />Turbo: <a
                        rel="noopener"
                        href="http://www.turboadv.com/white-rabbit-privacy-policy/ "
                        target="_blank"><u>http://www.turboadv.com/white-rabbit-privacy-policy/ </u></a>
                      <br /><br />AppNexus <a href="https://www.appnexus.com/en/company/platform-privacy-policy"
                        rel="noopener"
                        target="_blank"><u>https://www.appnexus.com/en/company/platform-privacy-policy</u></a><br /><br />La
                      mancata accettazione di cookie di profilazione non impedisce la navigazione del sito.<p></p>
                      <p><strong> 4. Durata dei Cookie</strong> <br /> <br />Alcuni cookie (cookie di sessione) restano
                        attivi solo fino alla chiusura del browser o all’esecuzione del comando di logout. Altri
                        cookie "sopravvivono" alla chiusura del browser e sono disponibili anche in successive
                        visite dell’utente. Questi cookie sono detti persistenti e la loro durata è fissata dal
                        server al momento della loro creazione. In alcuni casi è fissata una scadenza, in altri casi
                        la durata è illimitata. <br /> <br />Nexi utilizza cookie di profilazione della durata massimo
                        di 1 anno. </p>
                      <p></p>
                      <p><strong> 5. Gestione dei cookie nel browser</strong> <br /> <br />La maggior parte dei browser
                        internet sono inizialmente impostati per accettare i cookies in modo automatico. Ciò
                        significa che hai la possibilità, in ogni momento, di impostare il suo browser in modo da
                        accettare tutti i cookies, solo alcuni, oppure di rifiutarli, disabilitandone l’uso da parte
                        dei siti. Inoltre puoi normalmente impostare le preferenze del tuo browser in modo tale da
                        essere avvisato ogni volta che un cookie viene memorizzato nel tuo computer. Al termine di
                        ogni sessione di navigazione, infine, l’utente può cancellare dal disco fisso del tuo
                        dispositivo i cookies raccolti. <br /> <br /> Se vuoi eliminare i cookies installati nella
                        cartella dei cookies del browser utilizzato, ti ricordiamo che ciascun browser presenta
                        procedure diverse per la gestione delle impostazioni. <br />Ai seguenti link è possibile
                        reperire le informazioni utili per i browser più diffusi: <br /><br /><a
                          rel="noopener"
                          href="http://windows.microsoft.com/en-GB/windows7/How-to-manage-cookies-in-Internet-Explorer-9"
                          target="_blank" className="cl-custom-text-bold-and-color">Internet Explorer</a><br /><br /> <a
                          rel="noopener"
                          href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies"
                          target="_blank" className="cl-custom-text-bold-and-color">Firefox</a><br /><br /> <a
                          rel="noopener"
                          href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank"
                          className="cl-custom-text-bold-and-color">Google Chrome</a><br /><br /> <a
                          rel="noopener"
                          href="https://support.apple.com/kb/PH5042?locale=it_IT" target="_blank"
                          className="cl-custom-text-bold-and-color">Safari</a><br /> </p>
                      <p></p>
                    </div>
                    <div>
                      <p><br />Puoi ricevere le medesime istruzioni in formato elettronico, rivolgendoti in qualsiasi
                        momento al nostro Servizio Clienti inviando una mail all’indirizzo <a
                          rel="noopener"
                          href="mailto:c.satisfaction@nexi.it" target="_blank">c.satisfaction@nexi.it</a>. <br />
                        <br /> Questa pagina è visibile dal link presente nel footer del Sito ai sensi dell’art. 122
                        secondo comma del D.lgs. 196/2003 e a seguito del Provvedimento Generale del Garante Privacy
                        "Individuazione delle modalità semplificate per l’informativa e l’acquisizione del consenso
                        per l’uso dei cookie" pubblicata sulla Gazzetta Ufficiale n.126 del 3 giugno 2014 e relativo
                        registro dei provvedimenti n.229 dell’8 maggio 2014.</p>
                    </div>
                    <div
                      onClick={() => setCookiesOpen(false)}
                      className="cl-banner-button"
                    >
                      <span className="cl-savesetting-button">
                        Salva
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }



        </div>
      }

      </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
