import React from 'react'
import PropTypes from 'prop-types'

import Logo from 'assets/images/logo--light.svg'

const Topbar = (props) => {
	return (
		<div className="Topbar">
			<div className="inner">
				<a href="https://www.nexi.it">
					<img src={Logo} alt="nexi" />
				</a>
			</div>
		</div>
	)
}

Topbar.propTypes = {}

export default Topbar
