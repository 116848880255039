import React from 'react'

function Avviso() {
	return (
		<>
			{new Date() < new Date('2025/01/15') && (
				<div className="avviso">
					<div className="titolo_avviso">
						{/* <b>Pausa estiva</b> */}
						{/* <b>Pausa Natalizia</b> */}
						{/* <b>Il catalogo edizione 2022 sta per terminare.</b> */}
					</div>
					<div className="testo_avviso">
						Per gli <b>ordini effettuati entro il 30/11/2024</b> sui soli premi delle sezioni <b>OUTLET</b> e <b>CHRISTMAS COLLECTION</b>, la spedizione è immediata con <b>consegna entro il 20/12/2024</b>.   {' '}
						<br />
						Per tutti gli altri premi i tempi di spedizione restano invariati.
					</div>
				</div>
			)}
		</>
	)
}

export default Avviso
