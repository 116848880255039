import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Footer = (props) => {
	const [sitemapOpen, setSitemapOpen] = React.useState(false)
	const [helpOpen, setHelpOpen] = React.useState(false)
	const [helpType, setHelpType] = React.useState('privati')

	const toggleSitemap = () => {
		setSitemapOpen(!sitemapOpen)
	}

	return (
		<div
			id="footer"
			className={classNames({
				Footer: true,
			})}
		>
			<footer
				className={classNames({
					'base-footer': true,
				})}
			>
				<nav class="base-footer__nav">
					<ul class="base-footer__nav-items">
						<li class="base-footer__nav-item ">
							<a target="_blank" class="base-footer__nav-link " href="https://www.nexi.it/it/chi-siamo" title="Chi siamo">
								<span>Chi siamo</span>
							</a>
						</li>

						<li class="base-footer__nav-item ">
							<a target="_blank" class="base-footer__nav-link " href="https://www.nexi.it/it/governance" title="governance">
								<span>governance</span>
							</a>
						</li>

						<li class="base-footer__nav-item ">
							<a class="base-footer__nav-link " href="https://www.nexi.it/news.html" title="News" target="_blank">
								<span>News</span>
							</a>
						</li>

						<li class="base-footer__nav-item ">
							<a target="_blank" class="base-footer__nav-link " href="https://www.nexi.it/it/eventi" title="eventi">
								<span>eventi</span>
							</a>
						</li>

						<li class="base-footer__nav-item ">
							<a target="_blank" class="base-footer__nav-link " href="https://www.nexi.it/it/media" title="media">
								<span>media</span>
							</a>
						</li>

						<li class="base-footer__nav-item ">
							<a target="_blank" class="base-footer__nav-link " href="https://www.nexi.it/it/trasparenza" title="Trasparenza ">
								<span>Trasparenza </span>
							</a>
						</li>

						<li class="base-footer__nav-item ">
							<a target="_blank" class="base-footer__nav-link " href="https://www.nexi.it/it/normative" title="Normative">
								<span>Normative</span>
							</a>
						</li>

						<li class="base-footer__nav-item ">
							<a target="_blank" class="base-footer__nav-link " href="https://www.nexi.it/it/reclami" title="Reclami">
								<span>Reclami</span>
							</a>
						</li>

						<li class="base-footer__nav-item ">
							<a target="_blank" class="base-footer__nav-link " href="https://www.nexi.it/it/privacy" title="Privacy">
								<span>Privacy</span>
							</a>
						</li>

						<li class="base-footer__nav-item ">
							<a target="_blank" class="base-footer__nav-link cl-policy-link" href="https://www.nexi.it/it/cookie-policy" title="Cookie">
								<span>Cookie</span>
							</a>
						</li>

						<li class="base-footer__nav-item ">
							<a target="_blank" class="base-footer__nav-link " href="https://www.nexi.it/it/accessibilita" title="accessibilità">
								<span>accessibilità</span>
							</a>
						</li>

						<li class="base-footer__nav-item ">
							<a target="_blank" class="base-footer__nav-link " href="https://www.nexi.it/it/assistenza-clienti" title="assistenza">
								<span>assistenza</span>
							</a>
						</li>
					</ul>
				</nav>

				<div class="base-footer__secondary">
					<span class="base-footer__legal-text">© Nexi Payments SpA 2017-2023. All Rights Reserved.</span>

					{/* <ul class="base-footer__social-items">
						<li class="base-footer__social-item">
							<a target="_blank" class="base-footer__social-link " href="https://it-it.facebook.com/nexi/" title="Facebook">
								<em class="icon-facebook_circle"></em>
							</a>
						</li>

						<li class="base-footer__social-item">
							<a target="_blank" class="base-footer__social-link " href="https://www.youtube.com/nexipayments" title="YouTube">
								<em class="icon-youtube_circle"></em>
							</a>
						</li>

						<li class="base-footer__social-item">
							<a target="_blank" class="base-footer__social-link " href="https://twitter.com/nexipayments" title="Twitter">
								<em class="icon-twitter_circle"></em>
							</a>
						</li>

						<li class="base-footer__social-item">
							<a target="_blank" class="base-footer__social-link " href="https://it.linkedin.com/company/nexipayments" title="Linkedin">
								<em class="icon-linkedin_circle"></em>
							</a>
						</li>
					</ul> */}

					<div className="social">
						<a className="facebook" target="_blank" href="https://it-it.facebook.com/nexi/" title="Facebook">
							<i class="fa fa-facebook" aria-label="Facebook" aria-hidden="true" title="Facebook"></i>
						</a>
						<a className="youtube" target="_blank" href="https://www.youtube.com/nexipayments" title="YouTube">
							<i class="fa fa-youtube" aria-label="YouTube" aria-hidden="true" title="YouTube"></i>
						</a>
						<a className="twitter" target="_blank" href="https://twitter.com/nexipayments" title="Twitter">
							<i class="fa fa-twitter" aria-label="Twitter" aria-hidden="true" title="Twitter"></i>
						</a>
						<a className="twitter" target="_blank" href="https://it.linkedin.com/company/nexipayments" title="Linkedin">
							<i class="fa fa-linkedin" aria-label="Linkedin" aria-hidden="true" title="Linkedin"></i>
						</a>
					</div>
				</div>
			</footer>
		</div>
	)
}

Footer.propTypes = {}

export default Footer
