import React from 'react';
import { usePagination } from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  ul: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
  },
});

const Pagination = (props) => {
  const classes = useStyles();
  const { items } = usePagination(props);

  return (
    <nav className="Pagination">
      <ul className={classes.ul}>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = '…';
          } else if (type === 'page') {
            children = (
              <button type="button" className={selected ? 'active':''} style={{ fontWeight: selected ? 'bold' : undefined }} {...item}>
                {page}
              </button>
            );
          } else {
            children = (
              <button className={type} type="button" {...item}>
                {type}
              </button>
            );
          }

          return <li className={(type === 'start-ellipsis' || type === 'end-ellipsis')? 'ellipsis':''} key={index}>{children}</li>;
        })}
      </ul>
    </nav>
  );
}

export default Pagination;