import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';

import * as layouts from 'layouts';

import {
  Home,
  Product
} from 'views';

const PublicRoutes = (props) => {
  return (
    <Switch>
      <RouteWithLayout
        component={Home}
        exact
        layout={layouts.Main}
        path="/"
      />

      <RouteWithLayout
        component={Home}
        exact
        layout={layouts.Main}
        path="/premi/:category"
      />

      <RouteWithLayout
        component={Product}
        exact
        layout={layouts.Main}
        path="/prodotti/:name"
      />

      <RouteWithLayout
        component={Product}
        exact
        layout={layouts.Main}
        path="/:str"
      />

      <Redirect to={{
        pathname: '/',
        state: {redirectTo: props.redirectTo
      }}} />
    </Switch>
  );
};

export default PublicRoutes;
