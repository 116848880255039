import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Header = (props) => {
	return (
		<div
			className={classNames({
				Header: true,
				[props.category]: props.category,
			})}
		>
			<div className="shadow"></div>
			<div className="container">
				<div className="title">
					{props.category === 'ricette' && 'RICETTE'}
					{props.category === 'sport' && 'SPORT'}
					{props.category === 'tech' && 'TECH'}
					{props.category === 'passioni' && 'PASSIONI'}
					{props.category === 'benessere' && 'BENESSERE'}
					{props.category === 'viaggi' && 'VIAGGI'}
					{props.category === 'shopping' && 'SHOPPING'}
					{props.category === 'casa' && 'CASA'}
					{props.category === 'charity' && 'CHARITY'}
					{props.category === 'outlet' && 'OUTLET'}
					{props.category === 'edizione speciale' && 'Christmas Collection'}
				</div>

				{/* <div className="content">
					{props.category === 'ricette' && 'Una cucina a prova di chef'}
					{props.category === 'sport' && 'In forma anche a casa'}
					{props.category === 'tech' && 'Il futuro all’ordine del giorno'}
					{props.category === 'passioni' && 'Vivile in libertà'}
					{props.category === 'benessere' && 'Momenti di puro relax'}
					{props.category === 'viaggi' && "Nuova avventura all'orizzonte"}
					{props.category === 'shopping' && 'Acquisti senza pensieri'}
					{props.category === 'casa' && 'Arredi per tutti i gusti'}
					{props.category === 'charity' && 'Le cause che contano'}
					{props.category === 'outlet' && 'Per prendere al volo le occasioni'}
					{props.category === 'edizione speciale' && 'Offerte speciali per te'}
				</div> */}
			</div>
		</div>
	)
}

Header.propTypes = {}

export default Header
