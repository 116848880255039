import React, { Fragment } from 'react'

import { Grid, Slider } from '@material-ui/core'
import { Header, Pagination } from './components'
import { Card, Carousel } from '../components'

import * as utils from 'utils.js'
import useQueryString from 'hooks/useQueryString.js'
import { useLocation } from 'react-router-dom'

import axios from 'axios'
import config from 'config'
import Avviso from 'views/components/Avviso'

const itemsPerPage = 12

const Home = (props) => {
	const [search, setSearch] = useQueryString('combine', '')
	const [range, setRange] = useQueryString('field_points_value', null)
	//const [range, setRange] = React.useState(null)
	const [brand, setBrand] = useQueryString('field_brand_tid_selective', props.match.params.brand || 'All')
	const [category, setCategory] = useQueryString('tid', props.match.params.category || 'All')

	const [page, setPage] = useQueryString('page', 0)
	const [order, setOrder] = useQueryString('sort_order', 'asc')

	const [rangeBounds, setRangeBounds] = React.useState(null)
	const [brands, setBrands] = React.useState([])
	const [categories, setCategories] = React.useState([])
	const [pages, setPages] = React.useState(0)

	const [items, setItems] = React.useState([])

	const location = useLocation()

	const doSearch = () => {
		if (range && range.length < 2) return

		axios({
			method: 'post',
			url: `${config.apiURL}/api/v1/items/search`,
			data: {
				search: search,
				range: range,
				brand: brand,
				category: category,
				order: order,
			},
		})
			.then((res) => {
				if (res.data) {
					setItems(res.data.items)
					if (!rangeBounds) setRangeBounds(res.data.rangeBounds)
					if (!range) setRange(res.data.rangeBounds)
					setBrands(res.data.brands)
					setCategories(res.data.categories)

					setPages(Math.ceil(res.data.items.length / itemsPerPage), 1)
				}
			})
			.catch((error) => {
				if (config.debug) console.log(error)
			})
	}

	React.useLayoutEffect(doSearch, [range, brand, category, search, order, location])

	return (
		<div className="Home" id="home">
			<Header
				value={search}
				category={category}
				onChange={(event) => {
					setPage(0)
					setSearch(event.target.value)
				}}
			/>

			<div className="results">
				<div className="filter">
					<Grid container direction="row" spacing={1} justifyContent="center" alignItems="center">
						<Grid item xs={12} sm={2} className="slider">
							{range && range.length == 2 && rangeBounds && rangeBounds.length == 2 && (
								<Slider
									value={range.map((v) => parseInt(v))}
									onChange={(event, newValue) => {
										setPage(0)
										setRange(newValue)
									}}
									min={rangeBounds && rangeBounds[0]}
									max={rangeBounds && rangeBounds[1]}
									valueLabelDisplay="off"
									marks={[
										{ value: range[0], label: range[0] },
										{ value: range[1], label: range[1] },
									]}
								/>
							)}
						</Grid>

						<Grid item xs={12} sm={2}>
							<select
								value={brand}
								onChange={(event) => {
									setPage(0)
									setBrand(event.target.value)
								}}
							>
								<option value="All">TUTTI</option>
								{brands.length === 0 && brand && <option value={brand}>{brand.toUpperCase()}</option>}
								{brands.map((brand, i) => (
									<option key={i} value={brand}>
										{brand.toUpperCase()}
									</option>
								))}
							</select>
						</Grid>

						<Grid item xs={12} sm={2}>
							<select
								value={category}
								onChange={(event) => {
									setPage(0)
									setCategory(event.target.value)
								}}
							>
								<option value="All">TUTTI</option>
								{categories.length === 0 && category && <option value={category}>{category.toUpperCase()}</option>}
								{categories.map((category, i) => (
									<option key={i} value={category}>
										{category.toUpperCase()}
									</option>
								))}
							</select>
						</Grid>

						<Grid item xs={12} sm={2}>
							<select
								value={order}
								onChange={(event) => {
									setPage(0)
									setOrder(event.target.value)
								}}
							>
								<option value="asc">ORDINA CRESCENTE</option>
								<option value="desc">ORDINA DECRESCENTE</option>
							</select>
						</Grid>

						<Grid item xs={12} sm={2} className="search">
							<input
								value={search}
								placeholder="Search"
								onChange={(event) => {
									setPage(0)
									setSearch(event.target.value)
								}}
							/>
							<button id="global-search-btn" type="submit">
								{/* <i className="fa fa-search"></i> */}
								{/* <i className="fa-solid fa-magnifying-glass"></i> */}
								{/* <i class="fa fa-times" aria-hidden="true"></i> */}
								<img src={require('assets/images/search.png')} alt="search" className="searchicon" />
							</button>
						</Grid>
					</Grid>
				</div>

				<Avviso />

				<Grid container direction="row" spacing={3} justifyContent="center" alignItems="flex-start">
					{items.map((item, i) => (
						<Fragment key={i}>
							{i >= page * itemsPerPage && i < page * itemsPerPage + itemsPerPage && (
								<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
									<Card history={props.history} item={item} />
								</Grid>
							)}
						</Fragment>
					))}
				</Grid>

				{pages > 0 && (
					<Pagination
						boundaryCount={1}
						siblingCount={1}
						count={pages}
						page={page + 1}
						hidePrevButton={page === 0 || pages === 1}
						hideNextButton={page + 1 === pages || pages === 1}
						onChange={(event, page) => {
							setPage(page - 1)
							utils.scrollTo(document.querySelector('#home'))
						}}
					/>
				)}
			</div>

			{props.match.params.category && <Carousel />}
		</div>
	)
}

export default Home
