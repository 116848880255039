import React from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import Img from 'react-image'

import * as utils from 'utils.js'
import productImage from 'assets/images/product.png'

const Card = (props) => {
	const handleClick = (e) => {
		props.history.push(`/prodotti/${props.item.slug}`)
	}

	return (
		<div className="Card" onClick={handleClick}>
			<div className="name">{props.item.name}</div>

			<div className="image">
				<Img
					className="image"
					alt={props.item.name}
					src={[`https://storage.googleapis.com/iosi-bucket1/${props.item.picturename}`, productImage]}
					loader={<Skeleton className="loader" variant="rect" width={220} height={165} />}
					unloader={<Skeleton className="loader" variant="rect" width={220} height={165} animation={false} />}
				/>
			</div>

			<div className="text">
				<Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
					<Grid item xs="auto">
						<p>{props.item.points} punti</p>
					</Grid>

					<Grid item xs="auto">
						<div className="btn btn-product-req">RICHIEDI</div>
					</Grid>
				</Grid>
			</div>
		</div>
	)
}

Card.propTypes = {}

export default Card
