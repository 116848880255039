export default {
	name: 'nexi',
	// apiURL: 'https://iosi.nexi.it',
	//apiURL:'http://35.210.71.117',
	apiURL: '',
	debug: false,
	version: 0.01,

	google: {
		analytics_monitoring_id: 'UA-97656196-1',
		tag_manager_id: 'GTM-5SWX6Q',
	},
}
