import React from 'react';
import PropTypes from 'prop-types';

const Header = props => {



  return (
    <div className="Header">
      <div className="shadow"></div>
    </div>
  );
};

Header.propTypes = {

};

export default Header;
